//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-116:_7100,_7760,_4400,_5464,_1248,_2416,_2980,_4820;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-116')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-116', "_7100,_7760,_4400,_5464,_1248,_2416,_2980,_4820");
        }
      }catch (ex) {
        console.error(ex);
      }