import iagperformanceDefaults from '../iagperformance/config.js';

export default {
  includes: ['iagperformance'],
  ...iagperformanceDefaults,
  storeId: globalThis.Convermax.config?.storeId || 'iagoffroad',
  Widgets: [
    ...iagperformanceDefaults.Widgets,
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.themevale_header-Mobile .item--account',
        class: 'items cm_garage_container',
      },
      template: 'fitmentSearch/garage',
    },
  ],
};
