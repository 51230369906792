//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-116:_940,_1988,_5628,_8884,_1002,_764,_6280,_3192;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-116')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-116', "_940,_1988,_5628,_8884,_1002,_764,_6280,_3192");
        }
      }catch (ex) {
        console.error(ex);
      }